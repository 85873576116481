.App {
  text-align: center;
  height: 100vh;
}
.HomeConatiner{
  background-color: #1b4597;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Receiptcontainer{
  height: "100%";
}
.container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: 100%;
  margin-bottom: 80px;
  height: "100%";
}

.webView {
  /* flex: 1; */
  /* height: 200vh; */
  /* width: 100%; */
  /* overflow: auto; */
}

.downloadButton {
  background-color: #1b4597;
  padding: 9px;
  align-self: flex-end;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  color: white;
  font-size: 17px;
}


/* NoOrderFound.css */

.no-order-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f2f2f2;
}

.no-order-content {
  text-align: center;
  max-width: 600px;
  padding: 20px;
}

.no-order-content h1 {
  font-size: 48px;
  color: #333;
  margin-bottom: 10px;
}

.no-order-content p {
  font-size: 18px;
  color: #666;
  line-height: 1.6;
}
.loading{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.loader {
  border: 4px solid rgba(0, 0, 0, 0.1); /* Light grey border */
  border-top: 4px solid #1b4597; /* Blue border */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}